import { StyleSheet } from "react-native";

/* Styles for the component Home */

const style = StyleSheet.create({
  backgroundImatges: {
    flex: 1,
    resizeMode: "cover",
    // justifyContent: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  descripcio: {
    width: "80%",
    textAlign: "center",
    alignSelf: "center",
    fontFamily: "Gothic",
    fontSize: 20,
  },
  questionBackground: {
    width: "80%",
    height: "80%",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 15,
    paddingVertical: 7,
  },

  titol: {
    fontSize: 20,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Gothic",
    fontWeight: "bold",
    textShadow:
      "-0.5vh -0.5vh 0.5vh #f2eed7, 0.5vh -0.5vh 0.5vh #f2eed7, -0.5vh 0.5vh 0.5vh #f2eed7, 0.5vh 0.5vh 0.5vh #f2eed7",
  },

  scroll: {
    flex: 1,
    width: "100%",
    height: 100,
  },

  quizzAnswerBackground: {
    alignItems: "center",
    paddingVertical: 10,
    gap: 12,
    backgroundColor: "#F9F7E8",
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
    flex: 1,
  },

  answerButton: {
    justifyContent: "center",
    width: "90%",
    height: "10%",
    backgroundColor: "#F9F7E8",
    border: "0.5vh solid #888",
    borderRadius: 32,
    flex: 1,
    borderWidth: 2,
  },

  answerButtonCorrect: {
    backgroundColor: "rgba(124, 196, 0, 0.2)",
    borderColor: "#7CC400",
  },

  answerButtonWrong: {
    backgroundColor: "rgba(238, 103, 89, 0.2)",
    borderColor: "#EE6759",
  },

  answerText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    color: "#3D3935",
    paddingLeft: 30,
  },

  botoContinuar: {
    justifyContent: "center",
    width: "90%",
    height: "20%",
    backgroundColor: "#00491F",
    borderRadius: 8,
  },
  textContinuar: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    flexDirection: "column",
    fontWeight: "400",
    fontSize: 20,
    // lineHeight: 100,
    color: "#FFFFFF",
    textAlign: "center",
  },
  arrowBack: {
    width: 40,
    height: 40,
    resizeMode: "contain",
    position: "absolute",
    top: 20,
    left: 10,
    zIndex: 100,
  },
  getHint: {
    width: 100,
    height: 35,
    resizeMode: "contain",
    position: "absolute",
    top: 40,
    right: 10,
    backgroundColor: "#c48e10",
    borderRadius: 4,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    zIndex: 100,
  },
  getHintText: {
    color: "#FFF",
    fontWeight: "700",
    fontSize: 12,
    textAlign: "center",
    width: "100%",
    fontStyle: "normal",
    letterSpacing: 0.5,
  },
});

export default style;
