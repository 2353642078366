import React, { useRef, useState } from "react";
import { Dimensions, Pressable, ScrollView, View } from "react-native";
import style from "@/Components/Screens/Home/HomeScreenStyle";
import HomeCard from "./HomeCard";
import { useGlobal } from "@/Components/Context/globalContext";

interface InfoHomeProps {
  onChange: any;
}

export default function InfoHome({ onChange }: InfoHomeProps) {
  const { projecte } = useGlobal();
  const [activeButtonScroll, setActiveButtonScroll] = useState<number>(0);
  const scrollViewRef = useRef(null);

  const handleScroll = async (event: any) => {
    return;
    const contentOffset = event.nativeEvent.contentOffset.x;
    const viewSize = event.nativeEvent.layoutMeasurement.width;
    const pageIndex = Math.floor(contentOffset / viewSize);
    setActiveButtonScroll(pageIndex);

    onChange(projecte.itineraris[pageIndex]);
  };

  const scrollToPage = (index: number) => {
    const screenWidth = Dimensions.get("window").width;
    // @ts-ignore
    scrollViewRef.current.scrollTo({
      x: index * screenWidth,
      animated: true,
    });
  };

  const renderPaginationButtons = () => {
    return Array.from({ length: projecte.itineraris.length }, (_, index) => {
      const isHighlighted = index === activeButtonScroll;
      return (
        <Pressable
          key={index}
          style={[
            isHighlighted ? style.buttonScrollHighlighted : style.buttonScroll,
            {
              backgroundColor: projecte.color_secundari,
            },
          ]}
          onPress={() => scrollToPage(index)}
        />
      );
    });
  };

  return (
    <View
      style={{
        flexDirection: "column",
      }}
    >
      <View style={style.carouselContainer}>
        <ScrollView
          ref={scrollViewRef}
          pagingEnabled
          horizontal
          onScroll={handleScroll}
          showsHorizontalScrollIndicator={false}
        >
          {projecte.itineraris.map((itinerari: any) => (
            <HomeCard itinerari={itinerari} key={itinerari.id} />
          ))}
        </ScrollView>
      </View>

      {/*{projecte.itineraris.length > 1 && (*/}
      {/*  <View style={style.containerPagination}>*/}
      {/*    {renderPaginationButtons()}*/}
      {/*  </View>*/}
      {/*)}*/}
    </View>
  );
}
