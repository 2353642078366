import * as React from "react";
import { useEffect, useState } from "react";
import {
  Image,
  ImageBackground,
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import globalstyle from "../../Styles/Globals/globals";
import { useGlobal } from "@/Components/Context/globalContext";
import HintModal from "@/Components/Screens/Modals/HintModal";
import ModalPremis from "@/Components/Screens/Modals/ModalPremis";
import ModalInfoPoi from "@/Components/Screens/Modals/ModalInfoPoi";
import { FontAwesome5 } from "@expo/vector-icons";

const JocComponent = ({ children, RouterData, setPistesDemanades }: any) => {
  const { imatgeRecurs, projecte, __, textRecurs } = useGlobal();
  const [modalPistesVisible, setModalPistesVisible] = useState<boolean>(false);
  const [modalPremisVisible, setModalPremisVisible] = useState<boolean>(false);
  const [modalInfoVisible, setModalInfoVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<number>(0);

  let { poi, pregunta, itinerari } = RouterData.route.params;

  /** Si la pregunta no és undefined, és un trivial i el poi és el trivial */
  poi = pregunta ?? poi;

  const showButtonPoiInfo = !!__(poi, "popup_multimedia")?.url;

  const pistes: string[] = [];
  for (let i = 0; i < 4; i++) {
    const pista = __(poi, `pista${i + 1}`);

    if (pista) {
      pistes.push(pista);
    }
  }

  const tePistes = pistes.length > 0;

  useEffect(() => {
    setPistesDemanades(errors);
  }, [errors]);

  const RenderQuestion = ({ poi }) => {
    return (
      <View style={style.quizzHeader}>
        <Text style={style.questionTitle}>{__(poi, "titol")}</Text>
        <Text style={style.questionText}>{__(poi, "descripcio")}</Text>
      </View>
    );
  };

  const handleBack = () => {
    if (Platform.OS !== "web" && Keyboard.isVisible()) {
      return Keyboard.dismiss();
    }

    RouterData.navigation.goBack();
  };

  return (
    <View style={style.mainContainer}>
      <ImageBackground
        source={{
          uri: itinerari.fons?.url,
        }}
        style={[globalstyle.backgroundImatges, { gap: 20 }]}
      >
        <View style={[style.arrowBack]}>
          <TouchableOpacity style={style.arrowBack} onPress={handleBack}>
            <Image
              source={{ uri: imatgeRecurs("boton_atras") }}
              style={{ height: 30, width: 30 }}
            />
          </TouchableOpacity>
        </View>

        <RenderQuestion poi={poi} />

        <View style={style.liniaBotons}>
          {showButtonPoiInfo && (
            <TouchableOpacity
              onPress={() => setModalInfoVisible(true)}
              style={[
                style.liniaBotonsBoto,
                { backgroundColor: projecte.color_principal },
              ]}
            >
              <FontAwesome5 name={"info"} style={style.getHintText} />
            </TouchableOpacity>
          )}

          <TouchableOpacity
            onPress={() => setModalPremisVisible(true)}
            style={[
              style.liniaBotonsBoto,
              { backgroundColor: projecte.color_principal },
            ]}
          >
            <FontAwesome5 name={"award"} style={style.getHintText} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => setModalPistesVisible(true)}
            style={[
              style.liniaBotonsBoto,
              { display: tePistes ? "flex" : "none" },
              { backgroundColor: projecte.color_principal },
            ]}
          >
            <FontAwesome5 name={"question"} style={style.getHintText} />
          </TouchableOpacity>
        </View>

        {children}
      </ImageBackground>

      {tePistes && (
        <HintModal
          modalVisible={modalPistesVisible}
          setModalVisible={setModalPistesVisible}
          pistes={pistes}
          errors={errors}
          setErrors={setErrors}
        />
      )}

      {modalPremisVisible && (
        <ModalPremis
          itinerari={itinerari}
          setModalVisible={setModalPremisVisible}
        />
      )}

      {modalInfoVisible && (
        <ModalInfoPoi
          itinerari={itinerari}
          poi={poi}
          setModalVisible={setModalInfoVisible}
        />
      )}
    </View>
  );
};
export default JocComponent;

const style = StyleSheet.create({
  mainContainer: {
    backgroundColor: "#f9f7e8",
    flex: 1,
    height: "100%",
    width: "100%",
  },
  arrowBack: {
    width: 40,
    height: 40,
    resizeMode: "contain",
    position: "absolute",
    top: 20,
    left: 10,
    zIndex: 100,
  },
  quizzHeader: {
    paddingHorizontal: 5,
    marginBottom: 10,
    flex: 1,
  },
  questionTitle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    fontSize: 28,
    gap: 12,
    color: "#FFF",
    paddingTop: 80,
    paddingLeft: 20,
  },
  questionText: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 18,
    gap: 12,
    color: "#FFF",
    paddingHorizontal: 20,
  },
  liniaBotons: {
    zIndex: 100,
    position: "absolute",
    top: 40,
    right: 10,
    flexDirection: "row",
    gap: 10,
  },
  liniaBotonsBoto: {
    padding: 10,
  },
  getHintText: {
    color: "#FFF",
    fontWeight: "700",
    fontSize: 12,
    textAlign: "center",
    fontStyle: "normal",
    letterSpacing: 0.5,
  },
});
