import React from "react";
import { StyleSheet, View, Text } from "react-native";
import { Image } from "expo-image";
import { useGlobal } from "@/Components/Context/globalContext";

const QRScreen = () => {
  const { __, projecte, textRecurs } = useGlobal();

  return (
    <View style={styles.fullScreen}>
      <Image
        source={{ uri: __(projecte, "qr")?.url }}
        style={[styles.fullScreen]}
        contentFit="contain"
      />
      <View style={styles.headerContainer}>
        <Text style={styles.headerText}>{__(projecte, "qr_web")}</Text>
      </View>
    </View>
  );
};

export default QRScreen;

const styles = StyleSheet.create({
  fullScreen: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  headerContainer: {
    position: "absolute",
    bottom: "10%",
    zIndex: 2,
    width: "100%",
  },
  headerText: {
    fontSize: 32,
    fontWeight: "bold",
    textAlign: "center",
    color: "white",
  },
});
