import React, { useEffect } from "react";
import { View } from "react-native";
import CarouselImagePerfil from "./CarouselImagePerfil";
import HeaderComponent from "@/Components/Components/HeaderComponent";
import AuthAppShell from "@/Components/Components/AuthAppShell";
import { useGlobal } from "@/Components/Context/globalContext";
import { useNavigation } from "@react-navigation/native";

export default function PuzzlePerfil(RouterData: any) {
  const { __ } = useGlobal();
  const itinerari = RouterData.route.params.itinerari;
  const mostraGif = RouterData.route.params.mostraGif ?? true;
  const esFinal = RouterData.route.params.esFinal;

  const navigation = useNavigation<any>();

  useEffect(() => {
    if (esFinal && itinerari.premi?.tipus === "Trucada") {
      setTimeout(() => {
        return navigation.navigate("PreviTrucada", {
          itinerari,
          premi: itinerari.premi,
        });
      }, 20 * 1000);
    }
  }, []);

  const handleClose = () => {
    if (esFinal) {
      return navigation.replace("Valoracions", {
        ...RouterData.route.params,
      });
    }

    return navigation.goBack();
  };

  return (
    <AuthAppShell uri={itinerari.fons?.url}>
      <View style={{ flex: 1 }}>
        <HeaderComponent
          title={__(itinerari, "nom")}
          showBack={false}
          showGoHome={true}
          showSettings={false}
          customBack={handleClose}
        />

        <CarouselImagePerfil itinerari={itinerari} mostraGif={mostraGif} />
      </View>
    </AuthAppShell>
  );
}
