import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { WebView } from "react-native-webview";
import { useGlobal } from "@/Components/Context/globalContext";
import { checkErrors } from "@/Components/Functions/MapFunctions";
import { normalizeText } from "@/Components/Functions/utils";
import { Letter } from "@/types/poiTypes";
import JocComponent from "@/Components/Components/JocComponent";
import globalstyle from "@/Styles/Globals/globals";

export default function Game360(RouterData: any) {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();

  /** Si la pregunta no és undefined, és un trivial i el poi és el trivial */
  let { poi, pregunta, itinerari, punts } = RouterData.route.params;
  poi = pregunta ?? poi;

  const has360Url = !!__(poi, "url_360");

  const textInputRefs = useRef<Array<TextInput | null>>([]);

  const [goReward, setGoReward] = useState<boolean>(
    itinerari.tipus === "Track",
  );

  const [errors, setErrors] = useState<number>(0);

  const resposta = __(poi, "resposta") || "";

  const [guessArray, setGuessArray] = useState<Letter[]>(
    new Array(resposta.length).fill({
      letter: "",
      correct: null,
    }),
  );

  const navigateToReward = () => {
    // Es trivial?
    if (!!pregunta) {
      if (RouterData.route.params.poi.tipus === "TrivialResum") {
        return RouterData.navigation.replace("Trivial", {
          ...RouterData.route.params,
          poi: RouterData.route.params.poi,
          punts: punts + checkErrors(errors),
        });
      }

      return RouterData.navigation.replace("Reward", {
        ...RouterData.route.params,
        poi,
        poiOriginal: RouterData.route.params.poi,
        punts: checkErrors(errors),
      });
    }

    RouterData.navigation.replace("Reward", {
      ...RouterData.route.params,
      punts: checkErrors(errors),
    });
  };

  const onWebViewMessage = () => {
    setGoReward(true);
  };

  const checkLetter = (char: string, position: number) => {
    setGuessArray((prevGuessArray: any) =>
      prevGuessArray.map((letter: string[], index: number) => {
        if (index !== position) return letter;

        let correct: boolean | null = null;
        if (char !== "") {
          correct = normalizeText(char) === normalizeText(resposta[position]);
        }

        return {
          ...letter,
          letter: char.toUpperCase(),
          correct,
        };
      }),
    );
  };

  const checkWin = () => {
    const spaceIndex = resposta.indexOf(" ");

    if (spaceIndex !== -1 && guessArray[spaceIndex].correct !== true) {
      setGuessArray((prevGuessArray: Letter[]) =>
        prevGuessArray.map((letter, pos) => {
          if (spaceIndex === pos) {
            return { ...letter, letter: " ", correct: true };
          }
          return letter;
        }),
      );
    }

    setGoReward(
      itinerari.tipus === "Track" ||
        (guessArray.length >= 1 &&
          guessArray.every((letter: Letter) => letter.correct === true)),
    );
  };

  const handleOnChangeText = useCallback(
    (text, index) => {
      if (text.length >= 1) {
        text = text[text.length - 1];
      }
      checkLetter(text, index);

      if (text.length === 0) {
        return;
      }

      setTimeout(() => {
        const nextIndex = index + 1;
        if (nextIndex < guessArray.length && text !== "") {
          const nextInputRef = textInputRefs.current[nextIndex];
          if (nextInputRef) {
            nextInputRef.focus();
          }
        }
      }, 1);
    },
    [guessArray.length],
  );

  const injectedJavaScript = `
    var script = document.createElement('script');
            script.src = 'https://static.kuula.io/api.js';
            script.onload = function() {
                KuulaPlayerAPI.addEventListener("hotspot", function(e) {
                    window.ReactNativeWebView.postMessage('hotspot');
                });
            };
            document.body.appendChild(script);
    true; // note: this is required, or you'll sometimes get silent failures
  `;

  useEffect(() => {
    if (Platform.OS === "web") {
      KuulaPlayerAPI.addEventListener("hotspot", function (e) {
        setGoReward(true);
      });
    }
  }, []);

  useEffect(() => {
    checkWin();
  }, [guessArray]);

  const WebIframe = useCallback(() => {
    if (poi.catalejo) {
      return (
        <View style={styles.spyglass}>
          <iframe
            src={__(poi, "url_360")}
            height={"100%"}
            width={"100%"}
            style={{
              border: "none",
            }}
          />
        </View>
      );
    }

    return (
      <iframe
        src={__(poi, "url_360")}
        height={"100%"}
        width={"100%"}
        style={{
          border: "none",
        }}
      />
    );
  }, [__, poi]);

  const GuessLetterInput = ({
    letter,
    index,
    onChangeText,
    resposta,
    textInputRefs,
  }) => (
    <KeyboardAvoidingView
      key={"GuessArray_" + index}
      behavior={"padding"}
      keyboardVerticalOffset={Dimensions.get("screen").height}
    >
      <TextInput
        ref={(ref) => (textInputRefs.current[index] = ref)}
        style={getGuessInputStyle(letter, resposta, index)}
        maxLength={1}
        value={letter.letter}
        editable={resposta[index] !== " "}
        onChangeText={(text) => onChangeText(text, index)}
      />
    </KeyboardAvoidingView>
  );

  const getGuessInputStyle = (letter, resposta, index) => {
    let baseStyle;

    if (resposta[index] === " ") {
      baseStyle = styles.letterIsSpace;
    } else if (letter.correct) {
      baseStyle = styles.guessSquareCorrect;
    } else if (letter.correct === false) {
      baseStyle = styles.guessSquareWrong;
    } else {
      baseStyle = styles.guessSquare;
    }

    const sizeStyle =
      resposta.length >= 8
        ? { width: 33, height: 40 }
        : { width: 35, height: 50 };

    return [baseStyle, sizeStyle];
  };

  return (
    <JocComponent RouterData={RouterData} setPistesDemanades={setErrors}>
      {has360Url && (
        <View style={[styles.iframeContainer]}>
          {Platform.OS === "web" ? (
            <WebIframe />
          ) : (
            <WebView
              source={{ uri: __(poi, "url_360") }}
              allowFullScreen={true}
              style={styles.iframe}
              injectedJavaScript={injectedJavaScript}
              onMessage={onWebViewMessage}
            />
          )}
        </View>
      )}

      <View
        style={{ ...styles.guessRow, ...(!has360Url && { marginTop: 100 }) }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          {guessArray.map((letter: Letter, index: number) => (
            <GuessLetterInput
              key={index}
              letter={letter}
              index={index}
              onChangeText={handleOnChangeText}
              resposta={resposta}
              textInputRefs={textInputRefs}
            />
          ))}
        </View>

        <TouchableOpacity
          onPress={navigateToReward}
          disabled={!goReward}
          style={[
            globalstyle.jocsBotoContinuar,
            !goReward ? { opacity: 0.5 } : { opacity: 1 },
            !has360Url && {
              marginTop: "auto",
              marginBottom: 10,
            },
            { backgroundColor: itinerari.color_fons2 },
          ]}
        >
          <Text style={globalstyle.jocsTextContinuar}>
            {textRecurs("onboarding_continuar")}
          </Text>
        </TouchableOpacity>
      </View>
    </JocComponent>
  );
}

const styles = StyleSheet.create({
  guessRow: {
    alignContent: "flex-start",
    alignSelf: "flex-start",
    backgroundColor: "#f9f7e8",
    width: "100%",
    justifyContent: "flex-start",
    borderTopEndRadius: 44,
    borderTopStartRadius: 44,
    paddingVertical: 20,
    // flexGrow: 1, TODO - OJO WEB
  },
  guessSquare: {
    backgroundColor: "#FAFAFA",
    borderWidth: 1,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    margin: 5,
    textAlign: "center",
    fontWeight: "bold",
  },
  guessSquareWrong: {
    backgroundColor: "#FDF1D2",
    borderWidth: 1,
    borderRadius: 6,
    margin: 5,
    fontWeight: "bold",
    textAlign: "center",
  },
  letterIsSpace: {
    backgroundColor: "#d3d6da",
    borderWidth: 2,
    borderColor: "#848484",
    borderRadius: 4,
    margin: 5,
    fontWeight: "bold",
    textAlign: "center",
  },
  guessSquareCorrect: {
    backgroundColor: "#B9EE7F",
    borderWidth: 2,
    borderColor: "green",
    borderRadius: 6,
    margin: 5,
    fontWeight: "bold",
    textAlign: "center",
  },
  iframeContainer: {
    flex: 2,
    borderRadius: 20,
    width: "90%",
    justifyContent: "center",
    alignSelf: "center",
    paddingBottom: 10,
    paddingTop: 5,
    backgroundColor: "black",
    // flexGrow: 1,
  },
  iframe: {
    height: "100%",
    width: "100%",
    borderRadius: 20,
    gap: 10,
  },
  spyglass: {
    height: 200, // Adjust as needed
    width: 200, // Adjust as needed
    borderRadius: 150, // This creates a circular 'spyglass' effect
    overflow: "hidden", // This ensures that the iframe doesn't spill out of the border
    borderWidth: 5,
    borderColor: "#000",
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 4px rgba(0,0,0,0.25)",
    margin: "auto",
  },
});
