import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { useGlobal } from "../../Context/globalContext";
import { checkErrors } from "@/Components/Functions/MapFunctions";
import { normalizeText } from "@/Components/Functions/utils";
import JocComponent from "@/Components/Components/JocComponent";
import globalstyle from "@/Styles/Globals/globals";

export default function JocEscriureParaula(RouterData: any) {
  const { __, textRecurs, imatgeRecurs, projecte } = useGlobal();

  /** Si la pregunta no és undefined, és un trivial i el poi és el trivial */
  let { poi, pregunta, itinerari, punts } = RouterData.route.params;
  poi = pregunta ?? poi;

  const [answer, answer2] = __(poi, "resposta").split(",");

  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false);
  const [isAnswerIncorrect, setIsAnswerIncorrect] = useState(false);
  const [resposta, setResposta] = useState("");
  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [errors, setErrors] = useState(0);

  useEffect(() => {
    let timeout: any;
    if (isAnswerCorrect) {
      timeout = setTimeout(() => setIsAnswerCorrect(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [isAnswerCorrect]);

  const navigateToReward = () => {
    // Es trivial?
    if (!!pregunta) {
      if (RouterData.route.params.poi.tipus === "TrivialResum") {
        return RouterData.navigation.replace("Trivial", {
          ...RouterData.route.params,
          poi: RouterData.route.params.poi,
          punts: punts + checkErrors(errors),
        });
      }

      return RouterData.navigation.replace("Reward", {
        ...RouterData.route.params,
        poi,
        poiOriginal: RouterData.route.params.poi,
        punts: checkErrors(errors),
      });
    }

    RouterData.navigation.replace("Reward", {
      ...RouterData.route.params,
      punts: checkErrors(errors),
    });
  };

  const respondre = () => {
    if (resposta.trim() === "") {
      return;
    }

    if (errors > 4) {
      return navigateToReward();
    }

    if (
      normalizeText(resposta) === normalizeText(answer) ||
      normalizeText(resposta) === normalizeText(answer2)
    ) {
      setIsAnswerCorrect(true);
      navigateToReward();
    } else {
      setIsAnswerIncorrect(true);
      setErrors(errors + 1);
      setTimeout(() => setIsAnswerIncorrect(false), 2000);
    }
  };

  return (
    <JocComponent RouterData={RouterData} setPistesDemanades={setErrors}>
      <View style={styles.guessRow}>
        <TextInput
          style={[
            styles.answerInput,
            {
              backgroundColor: isAnswerCorrect
                ? "#FAFAFA"
                : isAnswerIncorrect
                ? "red"
                : "#FAFAFA",
            },
          ]}
          value={resposta}
          onChangeText={(text) => {
            setResposta(text);
            setIsInputEmpty(text.length === 0);
          }}
        />

        <TouchableOpacity
          onPress={respondre}
          style={[
            globalstyle.jocsBotoContinuar,
            isInputEmpty ? { opacity: 0.5 } : { opacity: 1 },
            { backgroundColor: itinerari.color_fons2 },
          ]}
          disabled={isInputEmpty}
        >
          <Text style={globalstyle.jocsTextContinuar}>
            {textRecurs("onboarding_continuar")}
          </Text>
        </TouchableOpacity>
      </View>
    </JocComponent>
  );
}

const styles = StyleSheet.create({
  guessRow: {
    alignContent: "flex-start",
    alignSelf: "flex-start",
    backgroundColor: "#f9f7e8",
    width: "100%",
    justifyContent: "flex-start",
    borderTopEndRadius: 44,
    borderTopStartRadius: 44,
    paddingVertical: 20,
    flexGrow: 0.5, // 1, TODO - OJO WEB
    flex: 10,
  },
  answerInput: {
    alignSelf: "center",
    borderWidth: 1,
    borderRadius: 20,
    marginVertical: 10,
    paddingVertical: 5,
    fontWeight: "bold",
    textAlign: "center",
    width: "80%",
  },
});
