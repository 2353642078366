import React from "react";
import { Image, Text, View, StyleSheet, Dimensions } from "react-native";
import { useGlobal } from "../../Context/globalContext";

const RenderTimer = ({ time, poi, isVisible }) => {
  const { __, imatgeRecurs } = useGlobal();

  const getBackgroundColor = () => {
    if (time[0] === "1") {
      return "linear-gradient(0deg, rgba(237, 142, 0, 0.7), rgba(237, 142, 0, 0.9)), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%), #BBE0C4;";
    } else if (time[0] === "0") {
      return "linear-gradient(0deg, rgba(255, 1, 1, 0.7), rgba(255, 1, 1, 0.9)), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%), #BBE0C4;";
    } else {
      return "linear-gradient(0deg, rgba(124, 196, 0, 0.7), rgba(124, 196, 0, 0.9)), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%), #BBE0C4;";
    }
  };

  const timerBoxStyle = {
    backgroundColor: getBackgroundColor(),
    display: isVisible ? "flex" : "none",
  };

  return (
    <View style={[style.timerBox, timerBoxStyle]}>
      <View style={style.timer}>
        <Image
          source={{ uri: imatgeRecurs("poi_reloj") }}
          style={{ width: 38, height: 38 }}
        />
        <Text style={style.timerText}>{time}</Text>
      </View>
      <Text style={style.timerPista}>
        {__(poi, "popup_titol")} {__(poi, "popup_text")}
      </Text>
    </View>
  );
};

export { RenderTimer };

const style = StyleSheet.create({
  timerBox: {
    width: "80%",
    justifyContent: "center",
    alignSelf: "center",
    borderWidth: 1,
    borderColor: "#7CC400",
    position: "absolute",
    bottom: 0,
    left: "10%",
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    paddingBottom: 10,
  },
  timer: {
    flexDirection: "row",
    justifyContent: "center",
    alignSelf: "center",
    alignItems: "center",
  },
  timerText: {
    color: "#00491F",
    fontSize: 48,
    paddingLeft: 10,
    fontWeight: "700",
  },
  timerPista: {
    width: "90%",
    textAlign: "center",
    fontSize: 16,
    alignSelf: "center",
    color: "#484848",
  },
});
