import React, { useEffect, useRef, useState } from "react";
import {
  Animated,
  Dimensions,
  ImageBackground,
  Platform,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  StyleSheet,
} from "react-native";
import { Image } from "expo-image";
import globalstyle from "@/Styles/Globals/globals";
import constants from "@/config/constants";
import { useGlobal } from "@/Components/Context/globalContext";

export default function OnBoardingScreen(RouterData: any) {
  const { textRecurs, imatgeRecurs, projecte } = useGlobal();

  const [activeButtonScroll, setActiveButtonScroll] = useState(0);
  const scrollViewRef = useRef(null);
  const [reachedEnd, setReachedEnd] = useState(false);

  const frasesPagina1: { tipus: string; value: string }[] = [];

  for (let i = 1; i <= 3; i++) {
    const tipus = `onboarding_pagina1_texto${i}`;

    if (textRecurs(tipus) !== tipus) {
      frasesPagina1.push({
        tipus,
        value: textRecurs(tipus),
      });
    }
  }

  const navigateToMain = () => {
    RouterData.navigation.goBack();
  };

  const navigateToSignup = () => {
    RouterData.navigation.navigate("LoginScreen", {
      ...RouterData.route.params,
    });
  };

  //Functional component: handle fade-in texto2
  const FadeInText = ({
    duration,
    delay,
  }: {
    duration: number;
    delay: number;
  }) => {
    const fadeAnim = useRef(new Animated.Value(0)).current;
    const [currentTextIndex, setCurrentTextIndex] = useState(0);

    useEffect(() => {
      const fadeIn = () => {
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: duration,
          useNativeDriver: Platform.OS !== "web",
        }).start(() => {
          setTimeout(() => {
            if (currentTextIndex === frasesPagina1.length - 1) {
              return;
            }

            setCurrentTextIndex((prevIndex) =>
              prevIndex === frasesPagina1.length - 1 ? 0 : prevIndex + 1,
            );
            fadeAnim.setValue(0);
          }, delay);
        });
      };

      fadeIn();
    }, [fadeAnim, frasesPagina1, duration, delay, currentTextIndex]);

    const renderTexts = () =>
      frasesPagina1.map((text: any, index: number) => {
        if (index <= currentTextIndex) {
          const opacity = fadeAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [index === currentTextIndex ? 0 : 1, 1],
          });

          return (
            <Animated.View key={index} style={{ opacity }}>
              {index === currentTextIndex && (
                <Animated.Text
                  style={{
                    opacity,
                    fontSize: 16,
                    fontFamily: "Roboto",
                    fontWeight: "700",
                    lineHeight: 22.4,
                    textAlign: "center",
                    color: constants.colorWhite,
                    marginBottom: 10,
                  }}
                >
                  {textRecurs(`onboarding_pagina1_texto${index + 1}`)}
                </Animated.Text>
              )}
            </Animated.View>
          );
        } else {
          return null;
        }
      });

    return <View>{renderTexts()}</View>;
  };

  const handleScroll = (event: any) => {
    const contentOffset = Math.ceil(event.nativeEvent.contentOffset.x);
    const viewSize = event.nativeEvent.layoutMeasurement.width;
    const pageIndex = Math.floor(contentOffset / viewSize);
    setActiveButtonScroll(pageIndex);

    if (pageIndex === 2) {
      setReachedEnd(true);
    } else {
      setReachedEnd(false);
    }
  };

  const scrollToPage = (index: number) => {
    const screenWidth = Dimensions.get("window").width;
    // @ts-ignore
    scrollViewRef.current.scrollTo({
      x: index * screenWidth,
      animated: true,
    });
  };

  const renderPaginationButtons = () => {
    return Array.from({ length: 3 }, (_, index) => {
      const isHighlighted = index === activeButtonScroll;

      return (
        <TouchableOpacity
          key={index}
          style={[
            isHighlighted ? style.buttonScrollHighlighted : style.buttonScroll,
            {
              backgroundColor: projecte.color_secundari,
            },
          ]}
          onPress={() => scrollToPage(index)}
        />
      );
    });
  };

  return (
    <View style={globalstyle.containerBg}>
      <ImageBackground
        source={{ uri: imatgeRecurs("onboarding_fons") }}
        style={globalstyle.backgroundImatges}
      >
        <View
          style={[
            {
              flex: 12,
              justifyContent: "center",
            },
            Platform.OS !== "web" && { alignItems: "center" },
          ]}
        >
          <ScrollView
            ref={scrollViewRef}
            pagingEnabled
            horizontal
            onScroll={handleScroll}
            scrollEventThrottle={16}
            showsHorizontalScrollIndicator={false}
            style={style.containerScrollView}
          >
            <View style={style.scroll}>
              <View style={style.logoScroll}>
                <Image
                  source={{ uri: imatgeRecurs("onboarding1") }}
                  alt="Onboarding 1"
                  style={style.imatgeScroll}
                  contentFit={"contain"}
                />
                <View
                  style={{
                    marginVertical: Platform.OS === "web" ? 20 : 40,
                    marginHorizontal: 40,
                  }}
                >
                  <FadeInText
                    duration={frasesPagina1.length > 1 ? 3500 : 0}
                    delay={frasesPagina1.length > 1 ? 2000 : 0}
                  />
                </View>
              </View>
            </View>
            <View style={style.scroll}>
              <View style={style.logoScroll}>
                <Image
                  source={{ uri: imatgeRecurs("onboarding2") }}
                  alt="Onboarding 2"
                  style={style.imatgeScroll}
                  contentFit={"contain"}
                />
                <View style={{ margin: 20, gap: 10 }}>
                  {textRecurs("onboarding_pagina2_texto1", undefined, "") && (
                    <Text style={style.titleStyle}>
                      {textRecurs("onboarding_pagina2_texto1", undefined, "")}
                    </Text>
                  )}
                  {textRecurs("onboarding_pagina2_texto2", undefined, "") && (
                    <Text style={[style.textStyle]}>
                      {textRecurs("onboarding_pagina2_texto2", undefined, "")}
                    </Text>
                  )}
                </View>
              </View>
            </View>
            <View style={style.scroll}>
              <View style={style.logoScroll}>
                <Image
                  source={{ uri: imatgeRecurs("onboarding3") }}
                  alt="Onboarding 3"
                  style={style.imatgeScroll}
                  contentFit={"contain"}
                />
                <View style={{ margin: 20, gap: 10 }}>
                  {textRecurs("onboarding_pagina3_texto1", undefined, "") && (
                    <Text style={style.titleStyle}>
                      {textRecurs("onboarding_pagina3_texto1", undefined, "")}
                    </Text>
                  )}

                  {textRecurs("onboarding_pagina3_texto2", undefined, "") && (
                    <Text style={[style.textStyle]}>
                      {textRecurs("onboarding_pagina3_texto2", undefined, "")}
                    </Text>
                  )}
                </View>
              </View>
            </View>
          </ScrollView>

          <TouchableOpacity
            style={{
              ...style.containerButtonGreenClear,
              backgroundColor: projecte.color_principal,
            }}
            onPress={
              reachedEnd
                ? navigateToSignup
                : () => scrollToPage(activeButtonScroll + 1)
            }
          >
            <Text
              style={{
                ...style.textButtonGreenClear,
              }}
            >
              {reachedEnd
                ? textRecurs("onboarding_iniciar")
                : textRecurs("onboarding_continuar")}
            </Text>
          </TouchableOpacity>

          <View style={style.containerPagination}>
            {renderPaginationButtons()}
          </View>
        </View>

        <TouchableOpacity
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          onPress={navigateToMain}
        >
          <View style={{ marginRight: 10, paddingTop: 4 }}>
            <Image source={{ uri: imatgeRecurs("onboarding_fletxa") }} />
          </View>
          <Text
            style={{
              color: constants.colorWhite,
              textAlign: "center",
              fontSize: 14,
              fontFamily: "Poppins",
              fontWeight: "400",
              lineHeight: 21,
            }}
          >
            {textRecurs("acceso_cambiar_idioma")}
          </Text>
        </TouchableOpacity>
      </ImageBackground>
    </View>
  );
}

const style = StyleSheet.create({
  scroll: {
    width: constants.windowWidth,
    height: constants.windowWidth,
    marginTop: 40,
  },
  logoScroll: {
    resizeMode: "contain",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  imatgeScroll: {
    width: "100%",
    flexGrow: 1,
  },
  containerChangeIdiom: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    color: constants.colorWhite,
    marginBottom: 70,
  },
  containerScrollView: {
    flex: 5,
    flexDirection: "column",
  },
  containerButtonGreenClear: {
    flex: 0.1,
    flexDirection: "row",
    marginHorizontal: 76,
    marginVertical: 20,
    color: constants.colorWhite,
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderStyle: "solid",
    borderRadius: 8,
    borderWidth: 2,
  },
  containerPagination: {
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 10,
    paddingHorizontal: 50,
    marginBottom: 20,
  },
  buttonScroll: {
    height: 20,
    width: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    backgroundColor: "rgba(124, 196, 0, 1)",
    opacity: 0.5,
    margin: 10,
  },
  buttonScrollHighlighted: {
    width: 20,
    height: 20,
    fontSize: 25,
    borderRadius: 20 / 2,
    borderWidth: 2,
    borderColor: constants.colorWhite,
    backgroundColor: constants.colorGreenClear,
    margin: 10,
  },

  textButtonGreenClear: {
    color: "white",
    flex: 2,
    textAlign: "center",
    fontSize: 16,
    fontWeight: "700",
    letterSpacing: 0.8,
  },
  cardContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 12,
    width: "85%",
    height: "100%",
    backgroundColor: constants.colorCardBg,
    paddingBottom: 24,
  },
  textStyleW: {
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: "700",
    lineHeight: 22.4,
    textAlign: "center",
    color: constants.colorWhite,
  },
  textStyle: {
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: "700",
    lineHeight: 22.4,
    textAlign: "center",
    color: constants.colorWhite,
  },
  titleStyle: {
    fontSize: 24,
    fontFamily: "Roboto",
    fontWeight: "700",
    lineHeight: 28.8,
    textAlign: "center",
    color: constants.colorWhite,
  },
});
