import { Dimensions } from "react-native";

/* Contains all the constants used in the app */

const colorCorporatiu = "white"; //not used
const colorBotons = "#f4efa2";
const colorText = "black"; // not used
const colorFonsMenu = "#f2eed7";
const colorTitols = "white"; // not used
const colorWarningMapa = "#ff0000";
const colorGreenDarkBtn = "#00491F";
const colorWhite = "#fff";
const colorGreenClear = "#7CC400";
const colorGreenDark = "#134B20";
const colorGreenDarkOp = "rgba(0, 73, 31, 0.23)";
const colorBgLogin = "#F9F7E8";
const colorBlackText = "#3D3935";
const colorLinearGx = "rgba(255, 255, 255, 0.2)";
const colorLinearGy = "rgba(227, 242, 224, 0.2)";
const colorOrange = "#C48E10";
const colorGreenClearBg = "#E2E8D6";
const colorGrey = "#484848";
const colorCardBg = "rgba(249, 247, 232, 0.5)";

const windowWidth = Dimensions.get("window").width;

export const MODAL_NO_VISIBLE = 0;
export const MODAL_VISIBLE = 1;
export const MODAL_TORNAT_OBRIR = 2;

export default {
  colorCorporatiu,
  colorBotons,
  colorText,
  colorFonsMenu,
  colorTitols,
  colorWarningMapa,
  windowWidth,
  colorGreenDarkBtn,
  colorWhite,
  colorGreenClear,
  colorGreenDark,
  colorBgLogin,
  colorBlackText,
  colorLinearGx,
  colorLinearGy,
  colorOrange,
  colorGreenClearBg,
  colorGrey,
  colorCardBg,
  colorGreenDarkOp,

  colorItinerari: "#009498",
  colorTextItinerari: "white",
  colorItinerariComencat: "green",
  colorItinerariFinalitzat: "grey",
};
