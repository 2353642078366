import { useAuth } from "@/Components/Context/authContext";
import { useGlobal } from "@/Components/Context/globalContext";
import React, { useCallback, useState } from "react";
import {
  Modal,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  View,
} from "react-native";
import globalstyle from "@/Styles/Globals/globals";
import { Image } from "expo-image";
import { WebView } from "react-native-webview";
import ImageViewer from "react-native-image-zoom-viewer";
import useMediaType from "@/hooks/useMediaType";
import { ResizeMode, Video } from "expo-av";

const PerfilPremis = (itinerari: any) => {
  const { user } = useAuth();
  const { imatgeRecurs, textRecurs } = useGlobal();

  const [modalPremiVisible, setModalPremiVisible] = useState(false);
  const [index, setIndex] = useState(0);

  const myItinerari = user.progres.itineraris[itinerari.id] ?? {
    pois: [],
  };

  const numFiles = Math.ceil(itinerari.pois.length / 2);

  const tincElPremiDelPoi = (id: number) => !!myItinerari.pois[id];

  const ModalPremiGaleria = ({
    modalPremiVisible,
    setModalPremiVisible,
    index,
  }) => {
    const premi = itinerari.pois[index].premi;

    return (
      <Modal
        transparent={false}
        visible={modalPremiVisible}
        onRequestClose={() => setModalPremiVisible(false)}
      >
        {premi.url ? (
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <View style={globalstyle.closeButton}>
              <Pressable
                onPress={() => setModalPremiVisible(false)}
                style={globalstyle.closeButtonPressable}
              >
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{ width: 16, height: 16 }}
                />
              </Pressable>
            </View>
            {Platform.OS === "web" ? (
              <iframe
                src={premi.url}
                height={"60%"}
                width={"100%"}
                style={{ border: "none" }}
              />
            ) : (
              <WebView
                source={{
                  uri: premi.url,
                }}
                style={{
                  border: "none",
                  height: 300,
                  width: 400,
                  flex: 1,
                }}
                allowFullScreen={true}
              />
            )}
          </View>
        ) : (
          <ImageViewer
            renderHeader={() => (
              <Pressable
                onPress={() => setModalPremiVisible(false)}
                style={{
                  ...globalstyle.closeButton,
                  backgroundColor: "white",
                  padding: 10,
                  borderRadius: 12,
                }}
              >
                <Image
                  source={{ uri: imatgeRecurs("boton_cerrar") }}
                  style={{ width: 13, height: 13 }}
                />
              </Pressable>
            )}
            imageUrls={[
              {
                url: premi.multimedia?.url,
              },
            ]}
          />
        )}
      </Modal>
    );
  };

  const quadrePremi = useCallback(
    (index: number) => {
      const premi = itinerari.pois[index]?.premi;
      const uri = premi?.multimedia?.url;

      const mediaType = useMediaType(uri);

      if (!premi || !uri) {
        return;
      }

      if (mediaType === "image") {
        return (
          <Image
            source={{
              uri,
            }}
            contentFit={"contain"}
            style={{
              alignSelf: "center",
              flex: 1,
              width: "95%",
              maxHeight: "100%",
              opacity: tincElPremiDelPoi(itinerari.pois[index].id) ? 1 : 0.2,
              borderRadius: 12,
            }}
          />
        );
      }

      if (mediaType === "video") {
        return (
          <Video
            source={{
              uri,
            }}
            style={{
              alignSelf: "center",
              flex: 1,
              width: "100%",
              maxHeight: "100%",
              opacity: tincElPremiDelPoi(itinerari.pois[index].id) ? 1 : 0.2,
              borderRadius: 12,
            }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
            rate={1.0}
            volume={1.0}
            isMuted={false}
            shouldPlay={false}
            videoStyle={{
              width: "100%",
              height: "100%",
            }}
          />
        );
      }
    },
    [itinerari.pois],
  );

  const mostraElPremi = (index: number) => {
    if (!tincElPremiDelPoi(itinerari.pois[index].id)) {
      return;
    }

    setIndex(index);
    setModalPremiVisible(true);
  };

  const filaDePremis = useCallback(
    (fila) => {
      return (
        <View style={style.fila}>
          <Pressable style={style.full} onPress={() => mostraElPremi(fila * 2)}>
            {quadrePremi(fila * 2)}
          </Pressable>

          <Pressable
            style={style.full}
            onPress={() => mostraElPremi(fila * 2 + 1)}
          >
            {quadrePremi(fila * 2 + 1)}
          </Pressable>
        </View>
      );
    },
    [quadrePremi],
  );

  return (
    <>
      <View
        style={{
          height: Platform.OS === "web" ? "90%" : "90%",
          overflow: "hidden",
        }}
      >
        <ScrollView>
          {Array.from({ length: numFiles }, (_, i) => (
            <View style={{ width: "100%" }} key={i}>
              {filaDePremis(i)}
            </View>
          ))}
        </ScrollView>
      </View>

      <ModalPremiGaleria
        modalPremiVisible={modalPremiVisible}
        setModalPremiVisible={setModalPremiVisible}
        index={index}
      />
    </>
  );
};

export default PerfilPremis;

const style = StyleSheet.create({
  full: { flex: 1, width: "100%", height: "100%" },
  fila: {
    flexDirection: "row",
    alignItems: "center",
    height: 150,
    gap: 10,
    marginBottom: 10,
  },
});
