import React, { useState } from "react";
import { Pressable, Text, TouchableOpacity, View } from "react-native";
import { Image } from "expo-image";
import { LinearGradient } from "expo-linear-gradient";

import constants from "@/config/constants";
import style from "../../Screens/Home/HomeScreenStyle";
import InfoModal from "../../Screens/Modals/InfoModal";
import { useNavigation } from "@react-navigation/native";
import { useGlobal } from "../../Context/globalContext";
import { useAuth } from "@/Components/Context/authContext";

export default function HomeCard({ itinerari }) {
  const { __, textRecurs, imatgeRecurs } = useGlobal();
  const { user } = useAuth();

  const [modalInfoVisible, setModalInfoVisible] = useState(false);

  const navigation = useNavigation<any>();

  const myItinerari = user.progres?.itineraris[itinerari.id] ?? {
    pois: {},
  };

  const poisPrincipals = itinerari.pois.filter((p) => p.latitud);

  const poisCompletats = Object.values(myItinerari.pois).filter((p) =>
    poisPrincipals.map((p) => p.id).includes(p.id),
  );

  const numPoisCompletats = poisCompletats.length;

  const myItinerariPoints: number = Object.values(
    myItinerari.pois,
  ).reduce<number>((acc, poi: any) => acc + poi.punts, 0);

  const maxPoints = itinerari.pois.reduce(
    (acc, poi) => acc + poi.premi.punts || 0,
    0,
  );

  return (
    <View style={style.logoScroll}>
      <View
        style={{
          ...style.cardContainer,
          backgroundColor: itinerari.color_fons1,
        }}
      >
        <View
          style={{
            marginTop: 10,
            marginLeft: "auto",
            ...style.marginContainer,
            ...(!itinerari.info1 && !itinerari.info2 && { display: "none" }),
          }}
        >
          <Pressable onPress={() => setModalInfoVisible(!modalInfoVisible)}>
            <Image
              source={{ uri: imatgeRecurs("boton_info") }}
              style={{ width: 32, height: 32, marginLeft: 3 }}
            />
          </Pressable>
        </View>
        <View style={style.scrollView}>
          <Image
            placeholder={require("@/assets/Images/loading.gif")}
            source={{ uri: itinerari.imatge?.url }}
            contentFit={"contain"}
            style={{ width: 100, height: 100 }}
          />
          <Text style={{ ...style.titleCard, color: itinerari.color_text1 }}>
            {__(itinerari, "nom")}
          </Text>
          <Text style={style.textCard}> {__(itinerari, "subtitol")} </Text>
        </View>

        {/*<View style={{ ...style.poisProperties }}>*/}
        {/*  <LinearGradient*/}
        {/*    colors={[constants.colorLinearGx, constants.colorLinearGy]}*/}
        {/*    style={[style.smallContainer, style.marginContainer]}*/}
        {/*    start={{ x: 0, y: 0 }}*/}
        {/*    end={{ x: 1, y: 1 }}*/}
        {/*  >*/}
        {/*    <Text style={{ marginRight: 5, color: itinerari.color_text1 }}>*/}
        {/*      {textRecurs("general_puntos")}*/}
        {/*    </Text>*/}
        {/*    <Text style={{ fontWeight: "700", color: itinerari.color_text1 }}>*/}
        {/*      {myItinerariPoints}/{maxPoints}*/}
        {/*    </Text>*/}
        {/*  </LinearGradient>*/}
        {/*  <LinearGradient*/}
        {/*    colors={[constants.colorLinearGx, constants.colorLinearGy]}*/}
        {/*    style={style.smallContainer}*/}
        {/*    start={{ x: 0, y: 0 }}*/}
        {/*    end={{ x: 1, y: 1 }}*/}
        {/*  >*/}
        {/*    <Text style={{ marginRight: 5, color: itinerari.color_text1 }}>*/}
        {/*      {textRecurs("general_lugares")}*/}
        {/*    </Text>*/}
        {/*    <Text style={{ fontWeight: "700", color: itinerari.color_text1 }}>*/}
        {/*      {numPoisCompletats}/{poisPrincipals.length}*/}
        {/*    </Text>*/}
        {/*  </LinearGradient>*/}
        {/*</View>*/}

        <View style={{ ...style.guadsContainer }}>
          <TouchableOpacity
            style={{
              borderRadius: 6,
              backgroundColor: itinerari.color_fons2,
              paddingVertical: 12,
              width: "100%",
            }}
            onPress={() =>
              navigation.navigate("ItinerariDetail", {
                itinerari,
              })
            }
          >
            <Text
              style={{ ...style.textBtnJugar, color: itinerari.color_text1 }}
            >
              {textRecurs(
                itinerari.tipus === "Track"
                  ? "onboarding_iniciar"
                  : "poi_jugar",
              )}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      {InfoModal(itinerari, modalInfoVisible, setModalInfoVisible)}
    </View>
  );
}
