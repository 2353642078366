import React, { useEffect } from "react";
import { ImageBackground, Pressable, View } from "react-native";
import globalstyle from "@/Styles/Globals/globals";
import { useNavigation } from "@react-navigation/native";
import useProjectData from "@/Components/Hooks/useProjectData";
import { useAuth } from "@/Components/Context/authContext";
import { StackNavigationProp } from "@react-navigation/stack";
import { useGlobal } from "@/Components/Context/globalContext";
import useIsWebAndNotMobile from "@/hooks/useIsWebAndNotMobile";
import Constants from "expo-constants";
import { getDestination } from "@/Components/Functions/MapFunctions";

const SplashScreen = () => {
  const { imatgeRecurs, projecte } = useGlobal();
  const { isLoading } = useProjectData();
  const { isAuthenticated } = useAuth();
  const isWebAndNotMobile = useIsWebAndNotMobile();

  const navigation = useNavigation<StackNavigationProp<any>>();

  const getSplashScreen = () => {
    const imageMap = {
      elsreptesdamer: require("@/assets/elsreptesdamer/splash_screen.png"),
      zarautz: require("@/assets/zarautz/splash_screen.png"),
    };

    return imageMap[Constants.expoConfig?.slug || ""];
  };

  const goToMain = () => {
    if (isLoading) {
      return;
    }

    if (isWebAndNotMobile) {
      return navigation.navigate("QRScreen");
    }

    if (isAuthenticated) {
      navigation.replace("Tabs");
      // navigation.navigate("Tours360");
      // navigation.navigate("PerfilScreen");
      // navigation.navigate("Valoracions");
      // const poi = projecte.itineraris[0].pois[10];
      // const destination = getDestination(poi.tipus);
      //
      // navigation.navigate(destination, {
      //   itinerari: projecte.itineraris[0],
      //   poi,
      // });
      // navigation.navigate("Map", { itinerari: projecte.itineraris[0] });
      // navigation.navigate("ItinerariDetail", {
      //   itinerari: projecte.itineraris[0],
      // });
      // navigation.navigate("MapTest", { itinerari: projecte.itineraris[0] });
      // navigation.navigate("PreviTrucada", {
      //   itinerari: projecte.itineraris[0],
      //   premi: projecte.itineraris[0].premi,
      // });
    } else {
      navigation.replace("IdiomaScreen");
      // navigation.navigate("OnBoarding");
      // navigation.navigate("LoginScreen");
    }
  };

  const splashScreen = getSplashScreen();

  useEffect(() => {
    setTimeout(() => goToMain(), isWebAndNotMobile ? 0 : 0);
  }, [isLoading, projecte]);

  if (isLoading || !projecte) {
    return (
      <View
        style={{
          flex: 1,
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      >
        <ImageBackground
          source={splashScreen}
          style={globalstyle.backgroundImatges}
        />
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <ImageBackground
        source={splashScreen}
        style={globalstyle.backgroundImatges}
      >
        <Pressable
          onPress={goToMain}
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            paddingTop: 150,
          }}
        >
          {/*TODO - posar spinner si fa falta*/}
          {/*<Image*/}
          {/*    source={require("../../assets/Estrella-gir.gif")}*/}
          {/*    style={{*/}
          {/*        width: "70%",*/}
          {/*        paddingTop: 100,*/}
          {/*        resizeMode: "contain",*/}
          {/*        flex: 1,*/}
          {/*    }}*/}
          {/*    alt="Gif Star"*/}
          {/*/>*/}
          {/*<Image*/}
          {/*    resizeMode="contain"*/}
          {/*    source={require("../../assets/Images/logosspinner3.png")}*/}
          {/*    alt="Footer Logos Project"*/}
          {/*    style={{*/}
          {/*        width: "100%",*/}
          {/*    }}*/}
          {/*/>*/}
        </Pressable>
      </ImageBackground>
    </View>
  );
};

export default SplashScreen;
